import { ModuleLink } from "components/common/moduleNavigation/moduleTypes/moduleTypes";
import factory from "assets/images/moduleNavigationIcons/factory.svg";
import { ServiceProvidersRouting } from "routes/manufacturingNew/ServiceProvidersRouting";

export const serviceProvider: ModuleLink = {
  url: "service-providers",
  label: "Podwykonawcy",
  icon: factory,
  routing: ServiceProvidersRouting,
};
